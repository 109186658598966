import React from "react"
import Seo from "../components/seo"
import {MainContainer, highlight, light} from "../components/styles"
import styled from "styled-components"

const ThanksHeader = styled.h1`
  margin-top: 10%;
  grid-column-start: 2;
  text-align: center;
  color: ${highlight};
  justify-self:center;
  align-self: center;
`
const ThanksText = styled.h4`
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
`

export default () => {
  return <>
    <Seo title="thank you"/>
    <MainContainer>
      <ThanksHeader>Thanks for contacting me!</ThanksHeader>
      <ThanksText>I'll get back to you as soon as possible.</ThanksText>
    </MainContainer>
  </>
}

